import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/code-factory.png");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/copilot-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/copilot.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/docs-and-help.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/gitlab.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/jfrog.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/jira.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/kuma.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/mtr.png");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/plantuml.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/shields-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/shields.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/sonarqube-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/sonarqube.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/wiki-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/wiki.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/yam.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/yopass.svg");
;
import(/* webpackMode: "eager", webpackExports: ["defaultTranslationValues"] */ "/app/apps/diamond-chest/src/i18n/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.26.3_next@15.1.5_react-dom@19.0.0_react@19.0.0__react@19.0.0__react@19.0.0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.26.3_next@15.1.5_react-dom@19.0.0_react@19.0.0__react@19.0.0__react@19.0.0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.5_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/image-component.js");
